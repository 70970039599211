
import CompForm from "../../../components/comp-form.vue"
import Request from "../../../module/request/request"

export default {
    components: {
        CompForm,
    },

    data() {
        return {
            structure: null,
            uuid: null,
        }
    },

    created() {
        this.getFormStructure()
    },

    activated() {
        this.uuid = this.$route.query.id
    },

    deactivated() {},

    methods: {
        getFormStructure() {
            Request.get("WEB://Goods/GetFormStructure").then(res => {
                this.structure = res
            })
        },
    },
}
