import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_comp_form = _resolveComponent("comp-form")!

  return (_openBlock(), _createBlock(_component_comp_form, {
    structure: $data.structure,
    uuid: $data.uuid,
    "submit-api": "WEB://Goods/Save",
    "detail-api": "WEB://Goods/FindByUuid"
  }, null, 8, ["structure", "uuid"]))
}